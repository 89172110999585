<template>
  <client-only>
    <div
      class="wish-btn"
      @click="handlerWishAdd"
    >
      <img
        v-if="!isWish"
        src="~/assets/img/svg/common/heart.svg"
        alt="избранное"
      >
      <img
        v-else
        src="~/assets/img/svg/common/heart-fill.svg"
        alt="избранное"
      >
    </div>
  </client-only>
</template>

<script setup lang="ts">
import useSitisWish from '#sitis/composables/useSitisWish';

const { $ym } = useNuxtApp();
const { productId } = defineProps<{
	productId: number;
}>();
const {
  getWishList,
  removeItemFromWishList,
  addToWishList,
  isInWish
} = useSitisWish();

const isWish = computed(() => isInWish(productId));

const handlerWishAdd = async () => {
  if (isWish.value) {
    await removeItemFromWishList(productId);
  } else {
    await addToWishList({ id: productId });

    if (typeof $ym === 'function') {
      $ym('reachGoal', 'add_to_wishlist');
    }
  }

  await getWishList();
};

</script>

<style scoped lang="scss">
.wish-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 38px;
	border-radius: 10px;
	background-color: white;
	cursor: pointer;

	img {
		transition: all 0.3s;
		filter: invert(73%) sepia(36%) saturate(788%) hue-rotate(310deg) brightness(92%) contrast(101%);
	}

	&:hover {
		img {
			transform: scale(1.1);
		}
	}
}
</style>
